import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetDamages } from "store/actions/damage/getDamages";
import { actionGetWears } from "store/actions/wear/getWears";
import { actionGetProviders } from "store/actions/provider/getProviders";
import { actionClearTireFilters } from "store/actions/tire/clearTireFilters";
import { actionSendToRevitalization } from "store/actions/tire/revitalization/sendToRevitalization";
import { actionSendToRepair } from "store/actions/tire/repair/sendToRepair";
import { actionSendToPile } from "store/actions/tire/pile/sendToPile";
import { actionGetRetirementCauses } from "store/actions/retirement/cause/getRetirementCauses";
import { actionGetDrivers } from "store/actions/driver/getDrivers";
import { actionGetRfids } from "store/actions/rfid/getRfids";
import { actionGetWarehouses } from "store/actions/warehouse/getWarehouses";
import { actionGetBrands } from "store/actions/brand/getBrands";
import { actionReturnFromRevitalization } from "store/actions/tire/revitalization/returnFromRevitalization";
import { actionReturnFromRepair } from "store/actions/tire/repair/returnFromRepair";
import { actionSendToWarehouse } from "store/actions/tire/warehouse/sendToWarehouse";
import { actionGetVehicleAxles } from "store/actions/vehicle/mount/getVehicleAxles";
import { actionGetTires } from "store/actions/tire/getTires";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";
import { alphabeticalSort } from "utils/sort";

import Page from "./page";
import { actionRetreadReject } from "store/actions/tire/revitalization/retreadReject";

function TireSendForm({ movementTireId, ...rest }) {
  const t = useLang();
  const location = useLocation();
  const { id: vehicleId } = useParams();
  const {
    to,
    tire,
    filter,
    vehicleTire,
    movement,
    tireActionId,
    open,
    onClose,
    setNotification,
    actionSendTire,
    getDamages,
    getWears,
    getProviders,
    getDrivers,
    getRetirementCauses,
    getRfids,
    getWarehouses,
    getBrands,
    getTires,
    getVehicleAxles,
    handleOpenSimilarForm,
  } = rest;

  let defaultInitialValues = {
    wears: [
      {
        wear_id: null,
        comment: "",
        image: null,
      },
    ],
    damages: [],
    provider_id: "",
    depth: "",
    retirement_cause_id: "",
    comment: "",
    image: null,
    cost_dispose_helmet: "",
    driver_id: "",
    surcharge: "",
    surcharge_item: "",

    warehouse_id: "",
    price: "",
    revitalized_brand: "",
    revitalized_tire_model_id: "",
    expected_durability: "",
    millimeter: "",
    invoice_folio: "",
    invoice_date: "",

    similar_tires: 1,
    code: "",
    device_code: "",

    with_review: false,
    date_send: "",
    date_return: "",

    repair_detail: false,
    repairs: [],
  };

  const {
    initialValues,
    initialErrors,
    resetFormik,
    setFormikErrors,
    setInitialValues,
    handleFormikValidate,
  } = useFormik({
    initialValues: defaultInitialValues,
  });

  const [damages, setDamages] = useState([]);
  const [wears, setWears] = useState([]);
  const [providers, setProviders] = useState([]);
  const [retirementCauses, setRetirementCauses] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [rfids, setRfids] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];

        if (["REVITALIZATION", "REPAIR", "PILE"].includes(to)) {
          data = await getWears(
            {
              scope: "wear_id,name,status,frequency",
            },
            false
          );
          setWears(data.sort((a, b) => b["frequency"] - a["frequency"]));

          data = await getDamages(
            {
              scope: "damage_id,name,status,frequency",
            },
            false
          );
          setDamages(data.sort((a, b) => b["frequency"] - a["frequency"]));

          data = await getProviders(
            {
              scope: "provider_id,name,status,subsidiary.name",
            },
            false
          );
          setProviders(
            data
              .map((x) => ({ ...x, name: `${x.name} | ${x.subsidiary.name}` }))
              .sort(alphabeticalSort("name"))
          );
        }

        if (to === "PILE") {
          data = await getRetirementCauses(
            {
              scope: "retirement_cause_id,name,status",
            },
            false
          );
          setRetirementCauses(data);

          data = await getDrivers(
            {
              scope: "driver_id,name,status",
            },
            false
          );
          setDrivers(
            data
              .map((driver) => ({
                driver_id: driver.driver_id,
                status: driver.status,
                name: `${driver.name}`,
              }))
              .sort(alphabeticalSort("name"))
          );
        }

        if (
          to === "WAREHOUSE" &&
          ["REVITALIZATION", "REPAIR"].includes(movement)
        ) {
          data = await getRfids(
            {
              scope: "rfid_id,device_code,status",
            },
            false
          );
          setRfids(data.sort(alphabeticalSort("device_code")));

          data = await getWarehouses(
            {
              scope: "warehouse_id,name,status,subsidiary.name",
            },
            false
          );
          setWarehouses(
            data
              .map((x) => ({ ...x, name: `${x.name} | ${x.subsidiary.name}` }))
              .sort(alphabeticalSort("name"))
          );

          data = await getBrands(
            {
              brandType: "RETREAD",
              scope: "brand_id,name,status,approved",
            },
            false
          );
          setBrands(data.sort(alphabeticalSort("name")));

          data = await getDrivers(
            {
              scope: "driver_id,name,status",
            },
            false
          );
          setDrivers(data.sort(alphabeticalSort("name")));
        }

        if (to === "WAREHOUSE" || to === "REJECTION") {
          data = await getRfids(
            {
              scope: "rfid_id,device_code,status",
            },
            false
          );
          setRfids(data.sort(alphabeticalSort("device_code")));

          data = await getWarehouses(
            {
              scope: "warehouse_id,name,status,subsidiary.name",
            },
            false
          );
          setWarehouses(
            data
              .map((x) => ({ ...x, name: `${x.name} | ${x.subsidiary.name}` }))
              .sort(alphabeticalSort("name"))
          );

          data = await getBrands(
            {
              brandType: "RETREAD",
              scope: "brand_id,name,status,approved",
            },
            false
          );
          setBrands(data.sort(alphabeticalSort("name")));
        }
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open) {
      fetchData();
    } else {
      resetFormik();
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      let fields = {};
      if (to === "REVITALIZATION" && movementTireId) {
        fields = {
          wears: [
            {
              wear_id: null,
              comment: "",
              image: null,
            },
          ],
          damages: [],
          provider_id: "",
          depth: "",
          date_send: "",
        };
      }
      if (to === "REPAIR") {
        fields = {
          damages: [
            {
              damage_id: null,
              comment: "",
              image: null,
            },
          ],
          provider_id: "",
          depth: "",
          date_send: "",
        };
      }

      if (to === "PILE") {
        fields = {
          retirement_cause_id: null,
          comment: "",
          image: null,
          damages: [],
          cost_dispose_helmet: "",
          driver_id: "",
          surcharge: "",
          surcharge_item: "",
          depth: "",
        };
      }

      if (to === "WAREHOUSE") {
        if (movement === "REVITALIZATION") {
          fields = {
            warehouse_id: "",
            price: "",
            comment: "",
            revitalized_brand: "",
            revitalized_tire_model_id: "",
            expected_durability: "",
            millimeter: "",
            invoice_folio: "",
            invoice_date: "",
            date_return: "",
          };
        }
        if (movement === "REPAIR") {
          fields = {
            warehouse_id: "",
            price: "",
            comment: "",
            invoice_folio: "",
            invoice_date: "",
            driver_id: "",
            surcharge: "",
            surcharge_item: "",
            date_return: "",
            repair_detail: false,
            repairs: [
              {
                repair_detail_name: "",
                repair_detail_cost: "",
              },
            ],
          };
        }

        if (movement === "MOUNT") {
          fields = {
            warehouse_id: "",
            depth: "",
          };
        }
      }

      if (to === "WAREHOUSE" && movementTireId === null) {
        fields = {
          warehouse_id: "",
          price: "",
          revitalized_brand: "",
          revitalized_tire_model_id: "",
          expected_durability: "",
          millimeter: "",
          invoice_folio: "",
          invoice_date: "",
          similar_tires: 1,
          code: "",
          device_code: "",
          date_return: "",
        };
      }

      if (movement === "MOUNT") {
        fields.with_review = false;
      }

      if (to === "REJECTION") {
        fields = {
          warehouse_id: "",
        };
      }

      setInitialValues(fields);
    }
  }, [open, to]);

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    const fields = {};

    if (values.provider_id) {
      fields.provider_id = values.provider_id;
    }

    if (values.wears && values.wears.length > 0) {
      fields.wears = values.wears.map((wear) => {
        return !!wear.image
          ? { ...wear, wear_id: wear.wear_id.wear_id }
          : { wear_id: wear.wear_id.wear_id, comment: wear.comment };
      });
    }

    if (values.damages && values.damages.length > 0) {
      fields.damages = values.damages.map((damage) => {
        return !!damage.image
          ? { ...damage, damage_id: damage.damage_id.damage_id }
          : { damage_id: damage.damage_id.damage_id, comment: damage.comment };
      });
    }

    if (values.retirement_cause_id) {
      fields.retirement_cause_id =
        values.retirement_cause_id.retirement_cause_id;
    }

    if (values.driver_id) {
      fields.driver_id = values.driver_id;
    }

    if (values.comment) {
      fields.comment = values.comment;
    }

    if (values.surcharge) {
      fields.surcharge = values.surcharge;
    }

    if (values.surcharge_item) {
      fields.surcharge_item = values.surcharge_item;
    }

    if (values.cost_dispose_helmet) {
      fields.cost_dispose_helmet = values.cost_dispose_helmet;
    }

    if (values.with_review) {
      fields.depth = values.depth;
    }

    if (values.image) {
      fields.image = values.image;
    }

    if (values.date_send) {
      fields.date_send = values.date_send;
    }

    if (values.date_return) {
      fields.date_return = values.date_return;
    }

    if (to === "REJECTION") {
      fields.warehouse_id = values.warehouse_id;
    }

    if (to === "WAREHOUSE" && movement === "REVITALIZATION") {
      fields.warehouse_id = values.warehouse_id;
      fields.price = values.price;
      fields.revitalized_tire_model_id = values.revitalized_tire_model_id;
      fields.depth = values.millimeter;

      if (values.expected_durability) {
        fields.expected_durability = values.expected_durability;
      }
      if (values.invoice_date) {
        fields.invoice_date = values.invoice_date;
      }
      if (values.invoice_folio) {
        fields.invoice_folio = values.invoice_folio;
      }
    }

    if (to === "WAREHOUSE" && movement === "REPAIR") {
      fields.warehouse_id = values.warehouse_id;
      fields.price = values.price;

      if (values.invoice_date) {
        fields.invoice_date = values.invoice_date;
      }
      if (values.invoice_folio) {
        fields.invoice_folio = values.invoice_folio;
      }

      if (values.repair_detail) {
        fields.repairs = values.repairs.map((x) => ({
          repair_name: x.repair_detail_name,
          price: x.repair_detail_cost,
        }));
      }
    }

    if (to === "WAREHOUSE" && movementTireId === null) {
      if (values.similar_tires > 1) {
        onClose();
        handleOpenSimilarForm(values.similar_tires, values);
      }
    } else {
      try {
        const params = {
          fields,
          movementTireId,
        };

        if (
          (to === "WAREHOUSE" && movement === "REVITALIZATION") ||
          to === "REJECTION"
        ) {
          params.tireRevitalizationId = tireActionId;
        }

        if (to === "WAREHOUSE" && movement === "REPAIR") {
          params.tireRepairId = tireActionId;
        }

        if (to === "WAREHOUSE" && movement === "MOUNT") {
          await actionSendTire(values.warehouse_id, movementTireId, fields);
        } else {
          await actionSendTire(params);
        }

        if (to === "REVITALIZATION") {
          setNotification({
            message: t("_messages.sent.revitalization"),
          });
        }
        if (to === "REPAIR") {
          setNotification({
            message: t("_messages.sent.repair"),
          });
        }
        if (to === "PILE") {
          setNotification({
            message: t("_messages.sent.pile"),
          });
        }
        if (to === "WAREHOUSE" || to === "REJECTION") {
          setNotification({
            message: t("_messages.sent.warehouse"),
          });
        }

        if (location.pathname.includes("mount")) {
          await getVehicleAxles(vehicleId);
        }
        onClose();
        getTires({ ...filter });
      } catch (error) {
        setFormikErrors(error, values, rest);
      }

      setSubmitting(false);
    }
  }

  function handleValidate(values) {
    let errors = handleFormikValidate(values);

    if (values.repair_detail) {
      const total = values.repairs.reduce(
        (acc, value) => acc + value.repair_detail_cost,
        0
      );

      if (total != values.price) {
        errors["repairs"] = [];
        errors["repairs"][0] = {};
        errors["repairs"][0]["repair_detail_cost"] = t(
          "_errors.different_cost"
        );
      }
    }
    return errors;
  }
  return (
    <Page
      t={t}
      to={to}
      open={open}
      tire={vehicleTire.code ? vehicleTire : tire}
      movement={movement}
      movementTireId={movementTireId}
      damages={damages}
      wears={wears}
      drivers={drivers}
      providers={providers}
      retirementCauses={retirementCauses}
      rfids={rfids}
      warehouses={warehouses}
      brands={brands}
      initialValues={initialValues}
      initialErrors={initialErrors}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleValidate}
    />
  );
}

TireSendForm.propTypes = {
  to: PropTypes.string.isRequired,
  movementTireId: PropTypes.number,
  movement: PropTypes.string,
  tireActionId: PropTypes.number,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleOpenSimilarForm: PropTypes.func,
};

TireSendForm.defaultProps = {
  to: "REVITALIZATION",
  movement: null,
};
const mapStateToProps = (state) => ({
  tire: state.Tire.Tire.tire,
  filter: state.Tire.Tire.filter_tire,
  vehicleTire: state.Vehicle.Tire.vehicle_tire,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const { to, movement } = ownProps;
  const actions = {
    setNotification: actionSetNotification(dispatch),
    getDamages: actionGetDamages(dispatch),
    getWears: actionGetWears(dispatch),
    getRetirementCauses: actionGetRetirementCauses(dispatch),
    getProviders: actionGetProviders(dispatch),
    getDrivers: actionGetDrivers(dispatch),
    getRfids: actionGetRfids(dispatch),
    getWarehouses: actionGetWarehouses(dispatch),
    getBrands: actionGetBrands(dispatch),
    clearTireFilters: actionClearTireFilters(dispatch),
    getTires: actionGetTires(dispatch),
    getVehicleAxles: actionGetVehicleAxles(dispatch),
  };

  switch (to) {
    case "REVITALIZATION":
      actions.actionSendTire = actionSendToRevitalization(dispatch);
      break;

    case "REPAIR":
      actions.actionSendTire = actionSendToRepair(dispatch);
      break;

    case "PILE":
      actions.actionSendTire = actionSendToPile(dispatch);
      break;

    case "WAREHOUSE":
      if (movement === "REVITALIZATION") {
        actions.actionSendTire = actionReturnFromRevitalization(dispatch);
      }

      if (movement === "REPAIR") {
        actions.actionSendTire = actionReturnFromRepair(dispatch);
      }

      if (movement === "MOUNT") {
        actions.actionSendTire = actionSendToWarehouse(dispatch);
      }
      break;

    case "REJECTION":
      actions.actionSendTire = actionRetreadReject(dispatch);
      break;
  }
  return actions;
};

export default connect(mapStateToProps, mapDispatchToProps)(TireSendForm);

export default {
  name: {
    singular: "Name",
    special: "Name(s)",
  },
  id: "ID",
  last_name_1: "Last name",
  last_name_2: "Second last name",
  email: "Email",
  password: "Password",
  status: "Status",
  corporate_name: "Corporate name",
  tradename: "Tradename",
  warehouse_name: "Warehouse name",
  driver_name: "Driver name",
  wear_name: "Wear name",
  damage_name: "Damage name",
  tire_code: "Tire code",
  title_tire_subsidiary: "Tire link with subsidiary",
  revitalized_tire_model_name: "Model",
  depth: "Depth",
  dual_difference: "Diferencia dual",
  current_depth: "Huella actual",
  remainder_depth: "Remainder depth",
  application: "Application",
  image: "image",
  social_reason: "Social Reason",
  rfc: "RFC",
  tire_fee: "Tire Fee",
  probable_causes: "probable causes",
  action_tire: "Action tire",
  action_vehicle: "Action vehicle",
  operation: "Operation",
  total: "Total",
  projection: "Km Projection",

  fee_field: {
    label: "Fee Currency Type",
    options: {
      mxn: "Mexican Coin (MXN)",
      usd: "US Dollar (USD)",
      eur: "European coin (EUR)",
    },
  },
  direction: "Direction",
  direction1: "Street",
  direction2: "Colony",
  province: "Province or municipality",

  internal_number: "Internal Number",
  external_number: "External Number",
  address: "Address",
  postal_code: "Postal code",
  burn_code: "Burn code",
  helmet_value: "Helmet value",
  helmet_value_revitalized: "Helmet value revitalized",
  created_by: "Created by",
  updated_by: "Updated by",
  created_at: "Registration date",
  done_by: "Done by",
  registered_by: "Registered by",
  assigned_by: "Assigned by",
  actioned_by: "{person} on {date} at {time}",
  time_at: "at",
  edited_by: "Edited by",

  search: "Search...",
  observation: "Observation",
  expected_durability: "Expected durability",
  price: "Price",
  retread_price: "Retread price",
  original_price: "Original price",
  axle_number: "Axle number",
  comment: {
    singular: "Comment",
    plural: "Comments",
  },

  drop_image: "Drop the image here or click to select it",

  country: "Country",
  state: "State",
  province: "Province",

  corporate: {
    singular: "Corporate",
    plural: "Corporates",
  },
  company: {
    singular: "Company",
    plural: "Companies",
  },
  user: {
    singular: "User",
    plural: "Users",
  },
  subsidiary: {
    singular: "Subsidiary",
    plural: "Subsidiaries",
  },
  tire: {
    singular: "Tire",
    plural: "Tires",
  },
  vehicle: {
    singular: "Vehicle",
    plural: "Vehicles",
    in_process: "Vehicles in process",
  },
  axle: {
    singular: "Axle",
    plural: "Axles",
  },
  driver: {
    singular: "Driver",
    plural: "Drivers",
  },
  warehouse: {
    singular: "Warehouse",
    plural: "Warehouses",
    default: "Select a warehouse",
  },
  division: {
    singular: "Division",
    plural: "Divisions",
    default: "Select a division",
  },
  tolerance_policy: {
    singular: "Tolerance policy",
    plural: "Tolerance policies",
  },
  inspection: {
    singular: "Inspection",
    plural: "Inspections",
  },
  travel: {
    singular: "Travel",
    plural: "Travels",
  },
  mounted_tire: {
    singular: "Mounted tire",
    plural: "Mounted tires",
  },
  review: {
    singular: "Review",
    plural: "Reviews",
  },
  movement_sheet: "Movement sheet",
  gps: "Gps",
  imei: "Imei",
  device_name: "Device name",
  size: "Size",
  layer: "Layers",
  tolerance: "Tolerance",
  brand: "Brand",
  code: "Code",
  transmission_brand: {
    label: "Transmission brand",
    default: "Select a transmission brand",
  },
  transmission_model: "Transmission model",
  transmission_speeds: "Transmission speeds",
  engine_brand: {
    label: "Engine brand",
    default: "Select an engine brand",
  },
  cylinder: "Cylinder",
  vehicle_type: {
    label: "Vehicle type",
    default: "Select a vehicle type",
  },
  vehicle_brand: {
    label: "Vehicle brand",
    default: "Select a vehicle brand",
  },
  vehicle_model: "Vehicle model",
  vehicle_year: "Vehicle year",
  auto_enrollment: "Auto enrollment",

  information: "Information",
  can_select_many: "You can select many",
  can_select_many_feminine: "You can select many",

  has_odometer: {
    label: "It has an odometer or hubodometer",
    simple: "Has odometer",
    helper_text:
      "Inspections made on vehicles without an odometer/hubodometer are NOT considered for performance reporting.",
  },

  address_value:
    "{direction1} {external_number}{internal_number}{direction2}, P.C {postal_code}",

  subsidiary_name: "Subsidiary name",
  unassigned_subsidiary: "Unassigned subsidiary",
  unassigned_division: "Unassigned division",

  yes: "Yes",
  no: "No",
  description: "Description",
  tire_quantity: "Tire quantity",

  economic_number: "Economic number",
  geofence_status: "Geofence status",
  inside: "Inside",
  outside: "Outside",

  date: {
    label: "Date",
    range: "Date range",
    from: "From",
    to: "To",
  },
  captured_by: "Captured by",
  inspection_date: "Inspection date",
  mount_date: "Mount date",
  odometer: "Odometer",
  with_odometer: "With odometer",
  without_odometer: "Without odometer",
  select_a_driver: "Select a driver",
  select_a_vehicle: "Select a vehicle",

  placeholder: {
    image: "Drop the image or click to select it",
  },

  company_policies: {
    general_policies: "General policies",
    number_cycle: "Maximum number of cycles",
    tire_rotation: "Rotation policy",
    inspection: "Inspection policy",
    days: "days",
    tolerance_inflation_pressure: "Tolerance for inflation pressure",
    directional_tire_rotation: "Steer rotation policy",
    tolerance_mating_pressure: "Tolerance for pressure (mating)",
    helmet_policy: "Helmet Policy",
    pressure_type_axle: "Recommended pressure per axle type",
    tire_condition_axle_type: "Tire condition by axle type",
    show_alerts_different_assignment: "Show alerts of different assignment",
    notify_inappropriate_pressures:
      "Notify operator of inappropriate pressures",
    tolerance_directional_mating_depth: "Tolerance for depth (steer)",
    mating_tolerance_directional_mating_depth:
      "Mating Tolerance for depth (steer)",
    tolerance_mating_depth: "Tolerance for depth",
    mating_tolerance_mating_depth: "Mating Tolerance for depth",
    send_pressure_setting: "Send pressure setting",
    operator_edit: "Operator can edit inspections",
    refection_review: "Inspect spare tires",
    use_checklist: "Use checklist",
    use_tpms: "Use TPMS",
    no_tire: "TPMS without tire management",
  },
  depth_tolerance_policies: {
    general_policies: "General policies",
    tire_application_id: "Tire Application",
    good_condition: "Good Condition",
    scheduled_withdrawal: "Scheduled Withdrawal",
    critical_withdrawal: "Critical Withdrawal",
    maximum_number_patches: "Maximum Number Patches",
    critical_number_patches: "Critical Number Patches",
  },

  country_field: {
    label: "Country",
    value: "Select a country",
  },

  tire_model_field: {
    label: "Model",
    value: "Select a model",
    helper_text: "Multiple can be selected",
  },

  state_field: {
    label: "State",
    value: "Select a state",
  },

  province_field: {
    label: "Province",
    value: "Select a province",
  },

  corporate_field: {
    singular: {
      label: "Corporate",
      value: "Select a corporate",
    },
    plural: {
      label: "Corporates",
      helper_text: "Multiple corporates can be selected",
    },
  },

  company_field: {
    singular: {
      label: "Company",
      value: "Select a company",
    },
    plural: {
      label: "Companies",
      helper_text: "Multiple companies can be selected",
    },
  },

  subsidiary_field: {
    singular: {
      label: "Subsidiary",
      value: "Select a subsidiary",
    },
    plural: {
      label: "Subsidiaries",
      helper_text: "Multiple subsidiaries can be selected",
    },
  },

  language_field: {
    label: "Language",
    options: { es: "Spanish", en: "English" },
  },

  tire_model: {
    model_name: "Model name",
    use: "Use",
    application: "Application",
  },

  type_of_route: {
    label: "Type of route",
    default: "Select a type of route",
    options: {
      local: "Local",
      foreign: "Foreign",
    },
  },

  tire_model_variation: {
    label: "Tire model variation",
    depth: "Depth",
    maximum_pressure: "Maximum pressure",
    recommended_pressure: "Recommended pressure",
    tolerance: "Pressure tolerance",
    number_layers: "Number of layers",
  },

  condition: "Condition",

  tire_condition: {
    default: "Select a condition",
    options: {
      renovated_new: "Retreaded new",
      renovated_used: "Retreaded used",
      original: "Original",
      revitalized: "Revitalized",
      both: "Both",
      original_new: "Original New",
      original_used: "Original Used",
      retread_new: "Retreaded New",
      retread_used: "Retreaded Used",
      renewed: "Renewed",
      total: "Total",
    },
  },

  tire_application: {
    label: {
      singular: "Tire application",
      plural: "Tire applications",
    },
    default: {
      singular: "Select the application",
      plural: "Select the applications",
    },
    helper_text: "Multiple applications can be selected",
    options: {
      directional: "Steer",
      trailer: "Trailer",
      all_position: "All position",
      traction: "Drive",
    },
  },

  tire_size: {
    label: {
      singular: "Tire size",
      plural: "Tire sizes",
    },
    default: {
      singular: "Select the size",
      plural: "Select the sizes",
    },
    helper_text: "Multiple sizes can be selected",
  },

  tire_repair: {
    not_repair: "Not under repair",
  },

  brand_type: {
    label: {
      singular: "Brand type",
      plural: "Brand types",
    },
    default: {
      singular: "Select a brand type",
      plural: "Select the brand types",
    },
    helper_text: "You can select many types of brands",
    options: {
      vehicle: "Vehicle",
      tire: "Tire",
      engine_transmission: "Engine Transmission",
      vehicle_engine: "Vehicle engine",
      retread: "Retread",
    },
  },

  tire_use: {
    options: {
      long_distance: "Long distance",
      mixed: "Mixed",
      regional: "Regional",
      urban: "Urban",
    },
  },
  vehicle_type_axle_position: {
    label: "Position",
    options: {
      frontal: "Frontal",
      central: "Central",
      rear: "Rear",
      refection: "Spare",
    },
  },

  revitalized_tire_model_application: {
    label: "Application",
    default: "Select a Application",
    options: {
      directional: "Steer",
      all_position: "All position",
    },
  },
  structure_type: {
    label: {
      singular: "Structure type",
      plural: "Structures types",
    },
    default: {
      singular: "Select a structure type",
      plural: "Select the structures types",
    },
    helper_text: "You can select many types of structures",
    options: {
      truck_tractor: "Truck tractor",
      truck: "Truck",
      box: "Box",
      dolly: "Dolly",
      van: "Van",
    },
  },

  maximum_number_patches: "Maximum number patches",
  critical_number_patches: "Critical number patches",
  scheduled_withdrawal: "Scheduled withdrawal",
  critical_withdrawal: "Critical withdrawal",
  good_condition: "Good condition",

  tire_status: "Tire status",

  preview: "Preview",

  provider: "Provider",

  invoice_date: "Invoice date",
  invoice_folio: "Invoice folio",

  repair_detail: "Repair detail",

  device_code: "Device code",
  not_rfid: "Without linked rfid",

  appearance: "Appearance",
  action_tire: "Action tire",
  probable_cause: "Probable cause",
  action_vehicle: "Action vehicle",
  action_operation: "Action operation",
  lock_cycle: "Block new cycles of revitalization",
  axle_field: {
    label: "Axle type",
    default: "Select an axle",
    options: {
      directional: "Steer",
      traction: "Drive",
      trailer: "Trailer",
      refection: "Refection",
    },
  },
  area: {
    label: "Area",
    default: "Select an area",
    options: {
      sidewall: "Sidewall",
      bead: "Bead",
      tread: "Tread",
      inside: "Inside",
      any: "Any",
      crown: "Crown",
      wheel: "Wheel",
    },
  },
  category: {
    label: "Category",
    default: "Select a category",
    options: {
      not_avoidable: "Not avoidable",
      avoidable: "Avoidable",
      helmet_failure: "Helmet failure",
      operation: "Operation",
      retread_repair: "Retread/Repair",
      road_risk: "Road risk",
      directional: "Steer",
      traction: "Drive",
      trailer: "Trailer",
    },
  },
  lock_cycles: "Lock cycles",
  is_root: "Is root",
  status: "Status",
  approved: "Approved",
  selected: {
    singular: {
      male: "Selected",
      female: "Selected",
    },
    plural: {
      male: "Selected",
      female: "Selected",
    },
  },
  division_name: "Division name",
  association_name: "Association name",
  association: "Association",
  vehicle_status: "Vehicle status",
  subsidiary_status: "Subsidiary status",
  alert: {
    label: "Alert",
    code: "Alert Code",
    suggestion: "Suggestion",
    details: "Details",
  },
  priority: {
    label: "Priority",
    default: "Select a Priority",
    helper_text: "Multiple can be selected",
    options: {
      high: "High",
      half: "Medium",
      low: "Low",
    },
  },
  alert_type: {
    label: "Alert Type",
    default: "Select a Alert Type",
    helper_text: "Multiple can be selected",
    options: {
      vehicle: "Vehicle",
      tire: "Tire",
      mount: "Mount",
    },
  },
  ranking_alert: {
    label: "Ranking Alert",
    default: "Select a Ranking Alert",
    options: {
      pressure: "Pressure",
      depth: "Depth",
      condition: "Condition",
      application: "Application",
      projection: "Projection",
      patches: "Patches",
      retirement: "Retirement",
      model: "Model",
      size: "Size",
      review: "Review",
      assignment: "Assignment",
      identification: "Identification",
      temperature: "Temperature",
    },
  },
  wheel: {
    label: "Wheel",
    default: "Select a wheel",
    options: {
      steel: "Steel",
      aluminum: "Aluminum",
      both: "Aluminum & steel",
    },
  },
  alerts: "Alerts",
  alert_status: "Alert status",
  disabled_alert: "Disabled alert",
  roles: "Roles",
  password_confirm: "Confirm password",
  change_password: "Change password to sing in",
  color: "Color",
  with_link_driver: "Show vehicles without driver",
  excel_placeholder: "Drop the excel document here or click to select it",
  model: "Model",
  models: "Models",
  cycles: "Cycles",
  condition: "Condition",
  location: {
    label: "Location",
    options: {
      warehouse: "WAREHOUSE",
      mount: "MOUNT",
      revitalization: "REVITALIZATION",
      pile: "SCRAP",
      repair: "REPAIR",
      initial: "INITIAL",
    },
  },
  roles_field: {
    default: "Select a rol",
  },
  rfid_field: {
    label: "RFID",
    default: "Select a device code",
  },
  provider_field: {
    label: "Provider",
    default: "Select a provider",
  },
  warehouse_field: {
    label: "Warehouse",
    default: "Select a warehouse",
  },
  brand_field: {
    label: "Brand",
    default: "Select a brand",
    multiple: "Multiple brands can be selected",
  },
  tire_model_variation_field: {
    label: "Size/Layers",
    default: "Select a size/layer",
    multiple: "Multiple can be selected",
  },
  dot: "DOT",
  similar_tires: "Number of similar tires to register",
  dot_initial: "Initial DOT",
  dot_final: "Final DOT",
  spare: "Spare",
  cap_and_casing: "Cap & casing",
  revitalized_tire_model_field: {
    label: "Revitalized model",
  },
  mileage: "Mileage",
  tire_days_service: "Days in service",
  tire_months_active: "Months active",
  review_date: "Review date",
  number_cycle: "Number cycle",
  pressure: "Pressure",
  not_warehouse: "Not warehouse",
  not_vehicle: "Not vehicle",
  wear_field: {
    default: "Select a wear",
  },
  wear: {
    plural: "Wear",
    singular: "Wear",
    month: "Last month wear",
  },
  damage_field: {
    default: "Select a damage",
  },
  damage: {
    plural: "Damages",
    singular: "Damage",
  },
  retirement_cause_field: {
    label: "Retirement cause",
    default: "Select a retirement cause",
  },
  cost_dispose_helmet: "Cost dispose helmet",
  surcharge: "Surcharge driver",
  surcharge_item: {
    label: "Surcharge item",
    default: "Select a surcharge item",
    options: {
      operation: "Operation",
    },
  },
  revitalized_brand_field: {
    label: "Revitalized brand",
    default: "Select a brand",
  },
  revitalized_model_field: {
    label: "Revitalized model",
    default: "Select a model",
  },
  tire_actions: "Tire actions",
  data_sheet: {
    label: "Data sheet",
    placeholder: "Drop the data sheet document here or click to select it",
    no: "No data sheet",
  },
  select_a_tire: "Select a tire",
  traveled_km: "Traveled km",
  number_patches: "Patches number",
  discard: "Discard",
  last_review_data: "Last review data",
  axle_information: "Axle information",
  axle_position: "Axle position",
  tire_position: "Tire position",
  axle_applications: "Application of tires allowed on the axle",
  with_review: "With review",
  last_review: "Last review",
  new_review: "New review",
  start_inspection: "Start inspection",
  inspection_in_process: "Inspection in process",

  review_type: {
    label: "Review type",
    options: {
      reset: "Reset",
      complete: "Complete",
      damage_and_wear: "Damages and wear",
      rotation: "Rotation",
      mount_dismount: "Mount / Dismount",
      pressure: "Pressure",
      identify: "Identify",
      initial: "Initial",
      partial: "Partial",
    },
  },
  depths: "Depths",
  depth_external: "External",
  depth_middle: "Middle",
  depth_internal: "Internal",
  line: "Line",
  reviewed: "Reviewed",
  not_reviewed: "Not reviewed yet",
  correct_tire: {
    label: "Correct tire?",
    yes: "Correct",
    no: "No correct",
  },
  all_tires: "All tires must be indicated",
  depth_min: "Min depth",
  depth_max: "Max depth",
  original_info: "Original info",

  tire_stats_report: {
    options: {
      brand_name: "Brands",
      model_name: "Models",
      model: "Models",
      depth: "Depths",
      size: "Sizes",
      number_cycle: "Cycles",
    },
    headers: {
      brand_name: "Brand",
      size: "Size",
      model_name: "Model",
      model: "Model",
      number_cycle: "Cycle",
      depth: "Depth",
      number_of_tires: "No. of tires",
    },
  },
  cant_move_tire: "Only stored tires can be moved",
  no_division: "No division",
  no_corporate: "No corporate",
  summary_report: {
    percent: "Percent",
    pressure_condition: {
      high: "High",
      very_high: "Very High",
      excessively_high: "Excessively high",
      exaggerately_high: "Exaggerately high",
      low: "Low",
      very_low: "Very low",
      excessively_low: "Excessively low",
      exaggerately_low: "Exaggerately low",
      normal: "Normal",
      no_pressure: "No pressure",
    },
    depth_condition: {
      good_condition: "Good condition",
      critical_withdrawal: "Critical withdrawal",
      scheduled_withdrawal: "Scheduled withdrawal",
    },
    report_show:
      "This report shows the current status of the tire (pressure, depth, alerts).",
    report_desc_strong: "Not all tire revisions are shown,",
    report_desc: "but only data from the last tire revision is shown.",
    disclaimers: {
      high_pressure:
        'The pressure classifications "High" and "Very High" corresponds to the pressure classification "High" in the summary report.',
      low_pressure:
        'The pressure classifications "Low" and "Very Low" corresponds to the pressure classification "Low" in the summary report.',
      no_pressure:
        'The pressure classification "No Pressure" corresponds to the tires that are stored.',
    },
  },
  damages_wears: {
    percent: "Percent",
    area: "Area",
    frequency: "Frequency",
    attribution: "Attribution",
    provider: "Provider",
    incidents: "Incidents",
  },
  report_warehouse: {
    percent: "Percent",
    movement_date: "Storage date",
    model: "Model",
    size: "Size",
    depth: "Depth",
    depth_condition: {
      good_condition: "Good condition",
      critical_withdrawal: "Critical withdrawal",
      scheduled_withdrawal: "Scheduled withdrawal",
    },
    tire_condition: {
      original_new: "Original New",
      original_used: "Original Used",
      retread_new: "Retreaded New",
      retread_used: "Retreaded Used",
    },
    tire_application: {
      all_position: "All Position",
      directional: "Steer",
      traction: "Drive",
      trailer: "Trailer",
    },
  },
  link_message:
    "Only one field can be filled in at a time from these options: Corporate - Company - Subsidiary",
  axles: {
    options: {
      frontal: "Frontal",
      central: "Central",
      rear: "Rear",
      refection: "Refection",
    },
  },
  autocomplete: {
    no_options: "No options",
    open: "Open",
    close: "Close",
    clear: "Clear",
  },
  tire_in_position: "Tire in position {position}:",
  reset_difference: "Reset gps difference",

  pressure_rating: "Classification pressure",
  depth_rating: "Classification depth",
  see_alerts: "See alerts",
  best_tire: "The best tire",
  best_performance_desc:
    "This report shows the best tires, those with the best performance based on their km",
  mm_pile_desc:
    "This report indicates the mm remaining of the tires when they are sent to pile",
  km_pile_desc:
    "This report shows the km traveled by the tires during their lifetime",
  best_performance_desc_small:
    "Only tires that have traveled more than 50,000 km in their current stage (original or revitalized) are taken into account",
  tire_cost: "Cost of tires according to their original model",
  actual_cost: "Current cost per Km",
  proposal: "Proposal",
  real_saving: "Actual savings per Km",
  cost: "Cost",
  revitalized_cost: "Cost of tires according to their revitalized model",
  best_tire_original: "Best original tires",
  best_tire_revitalized: "Best revitalized tires",

  user_review_report: {
    review_report: "Review report",
    report_show:
      "This report shows all the revisions made by the user to a vehicle in the established date range.",
    percent: "Percent",
  },
  warehouse_report: {
    variation: "Variation",
    revitalized_brand: "Revitalized brand",
    revitalized_model: "Revitalized model",
    report: "Report of tires in warehouses",
  },
  tires_mount_report: {
    report_of_tires: "Report of tires installed in vehicles",
    position: "Position",
    type: "Type",
  },
  damages_wears_report: {
    report_damage_wear: "Report of Damage and Wear to Tire Models",
    shows_tire_model:
      "This report shows tire models with percentages of damage and wear.",
    damage_counters: "Damage counters",
    wear_counters: "Wear counters",
  },
  dynamic_cost_report: {
    tabs: {
      general: "General",
      original: "Original",
      revitalized: "Revitalized",
      cycle: "Cycle",
    },
    cost_report: "Dynamic cost report by tire model",
    this_report_shows:
      "This report shows the cost of a tire of a certain model, in addition to the cost per km, per mm and performance km/mm.",
    only_tires:
      "Only tires (no spare) that have traveled more than 1000 km in their current stage (original or revitalized) are taken into account.",
    tire_cost: "Tire cost",
    tires: "Tires",
    cost_km: "Cost KM",
    cost_mm: "Cost MM",
    cost: "Cost",
    cost_of_tires: "Cost of tires according to their Original models",
    tire_cost_revitalized: "Tire Cost Based on Your Revitalized Models",
    cost_first_cycle: "Cost of tires on your first cycle",
    cost_second_cycle: "Cost of tires in your second cycle",
    cost_third_cycle: "Cost of tires in your third cycle",
    cost_fourth_cycle: "Cost of tires in your fourth cycle",
    originals: "Originals",
    options: {
      both: "Both",
      warehouse: "Warehouses",
      mount: "Mount",
    },
  },
  report_pile: {
    cycle: "Cycle",
    tires: "Tires",
    addition: "Addition mm",
    average: "Average mm useful",
    utils_mm: "Utils mm",
    cost_mm: "Average cost mm",
    cost_useful: "Average cost mm useful",
    summary_cost_mm: "Total cost mm useful",
    cost: "Cost",
    cost_km: "Cost km",
    originals: "Originals",
    revitalized: "Revitalized",
    number_of_tires: "Number of tires",
  },
  pile_summary_report: {
    pressure_classification: "Pressure classification",
    depth_classification: "Depth classification",
    alert_classification: "Alert classification",
    view_alerts: "View alerts",
    movements: {
      options: {
        both: "Both",
        warehouse: "Warehouse",
        mount: "Mount",
      },
    },
  },
  pile_performance_report: {
    show_report:
      "This report shows which is the tire with the best performance based on its km",
    paragraph_report:
      "Only tires that have more than 100,000 km traveled in their current stage (original or revitalized) are taken into account.",
    cost_tire: "Cost of tires according to your Original model",
    current_cost: "Current cost per Km",
    proposal: "Proposal",
    real_savings: "Real savings per Km",
    layers: "Layers",
    tires: "Tires",
    cost_10_km: "Cost * 10k/km",
    tire_cost_revitalized: "Tire cost based on your Revitalized model",
  },
  pile_stats_report: {
    number_of_tires: "No. Tires",
    range_of_depths: "Range of depths (mm)",
  },
  report_last_movement: {
    label: "Last Movement",
    last_position: "Last position",
    renovated_number: "Renovated number",
    type: "Type",
    shipping_date: "Shipping date to",
    depth_model: "Model tread depth",
    depth_last_revision: "Tread depth at last revision",
    wasted_rubber: "wasted rubber",
    cost: "Cost - MM",
    disposal_loss: "Disposal loss",
  },
  needs_report: "Needs report",
  suggested: "Suggested",
  quantity: "Quantity",
  originals: "Originals",
  revitalized: "Revitalized",
  calculated: "Calculated",
  original_cost: "Original cost",
  revitalized_cost: "Revitalized cost",
  ranking: "Ranking",
  cost_km: "Cost for km",
  required: "Required",
  stock: "Stock",
  to_buy: "To buy",
  last_purchase_price: "Last purchase price",
  last_purchase_date: "Last purchase date",
  center: "Center",
  start_date: "Start date",
  end_date: "End date",
  semaphore: "Semaphore",
  depth_semaphore: "Depth Semaphore",
  pressure_semaphore: "Pressure Semaphore",
  tires_by_condition: "Tires by condition",
  tires_by_application: "Tires by application",
  bad_mating: "Bad mating",
  costs: "Costs for mm",
  pressures: "Pressure",
  analysis: "Analysis",
  summary: "Summary",
  summary_desc:
    "Know the savings that can be generated by applying appropriate corrective actions in PRESSURE and TIRE DISASSEMBLY in a CRITICAL REMOVAL state.",
  floor_depth: "Floor depth",
  point_withdrawal: "Point withdrawal",
  air_pressure: "Air pressure",
  copyright: "Copyright {year} SETENAL | Privacy Policy - Terms of Use",
  type: "Type",
  originals: "Originals",
  vitalized: "Vitalized",
  tires_good_condition: "Tires in good condition",
  tires_to_be_renewed: "Tires to be renewed",
  tires_to_renew: "Tires to renew",
  total_tires_checked: "Total of tires checked",
  quantity_originals: "Quantity originals",
  quantity_revitalized: "Quantity revitalized",
  total_number_tires: "Total number of tires",
  investment_original_tires: "Investment of original tires",
  investment_renewed_tires: "Investment of renewed tires",
  wear_loss: "Wear loss",
  potential_savings: "Potential savings",
  quantity: "Quantity",
  description_code: "Description code",
  quantity_alerts: "Quantity of alerts",
  costo: "Cost",
  sum_of_mm: "Sum of mm",
  average_mm: "Average of mm",
  cost_mm: "Cost of mm",
  area_of_opportunity: "Area of opportunity",
  total_sample: "Total sample",
  total_base: "Total base",
  potencial_savings_fleet: "Tire fleet savings",
  tires_fleet: "Amount of tires with an inspection",
  savings_tires: "Savings per tire",
  durability: "Average durability per tire",
  savings_durability: "Savings per km per tire",
  estimated_fleet: "Total tires",
  savings_durability_fleet: "Fleet Savings per km",
  potential_national_savings: "Total Potential Savings",
  with_pressure: "With pressure",
  percent_of_loss: "% of loss",
  renovated: "Renovated",
  pressure_loss: "Pressure loss",
  original_cost: "Original cost",
  rev_cost: "Revitalized cost",
  original_loss_percentage: "% original loss",
  revitalized_loss_percentage: "% revitalized loss",
  average_price_per_tire:
    "Average price per tire (by model and number of tires)",
  report_summary: {
    location: {
      warehouse: "Warehouse",
      mount: "Mount",
      both: "Both (Warehouse & Mount)",
      pile: "Pile",
    },
    desc: "This report shows the last inspection of the tires included in the selected date period",
  },
  quant: "qty",
  report_savings: {
    condition: {
      advertencia: "Warning",
      normal: "Normal",
    },
  },
  show_refection: "Hide refection",
  with_mount_dismount: "Don't show Mount/Dismount",
  percent: "Percent",
  incidents: "Incidents",
  attribution: {
    options: {
      operator: "Operator",
      use_and_service: "Use and service",
      operation: "Operation",
      factory: "Factory",
      maintenance: "Maintenance",
      retreader: "Retreader",
      total: "Total",
      unclassifiable: "Unclassifiable",
      none: "Unclassifiable",
      provider: "Provider",
    },
  },
  evidence: "Evidence",
  original_tires: "Original tires",
  revitalized_tires: "Revitalized tires",
  finalized: "Finalized",
  semaphore_report: {
    fleet_report_desc: "This report shows the tires installed in all units",
    report_desc:
      "This report shows the reviewed tires of each unit in the selected time period. Even if the unit has all of its tires installed, only those that were reviewed will appear.",
    total_amount: "Total amount",
    unit: "Unit",
    units: "Units",
    green_info: "Good condition",
    yellow_info: "Scheduled withdrawal",
    red_info: "Critical withdrawal",
    summary_depth_description: "Depth Condition",
    summary_tire_description: "Tire Condition",
  },
  detail: "Detail",
  no_results: "No results",
  date_send: "Send date",
  date_return: "Return date",
  history: "History",
  select_alert: "Select an alert",
  repair_detail: "Repair detail",
  repair_detail_name: {
    label: "Repair name",
    default: "Select a name",
    options: {
      patch: "Patch",
      stuffed: "Stuffed",
    },
  },
  tire_revitalized_report: {
    title:
      "This report shows the number of tires send for retread per provider, and approximately how long the revitalization process takes",
    time: "Time approx.",
  },
  summary_warehouse_report: {
    title: "This report shows the number of unmounted tires per warehouse",
  },
  summary_mount_report: {
    title: "This report shows the number of tires mounted per vehicle type",
    time: "Time approx.",
  },
  tire_repair_report: {
    title:
      "This report shows the number of tires per provider and the approximation of how long the revitalization process takes",
    time: "Time approx.",
  },
  km_projection_report: {
    title: "Km Projection Report",
    label: "This report shows the km projection of the tires",
    km_projection: "Km Projection",
    km_projection_cost: "Km Projection Cost",
  },
  km_pile_report: {
    title: "Km Pile Report",
    average: "Travel average",
    cost_km: "Cost per Km",
    summary: "Travel summary",
  },
  alert_cause: "Alert cause",
  tire_review_date: "Tire review date",
  trend: "Trend",
  last_sampling: "Last sampling",
  current: "Current",
  losses: "Losses",
  savings: "Savings",
  original_investment: "Original investment",
  revitalized_investment: "Retread investment",
  revitalized_saving: "Retread saving",
  pressure_saving: "Pressure saving",
  proposed_saving: "Proposed saving",
  dashboard: {
    performance: {
      number_damage: "Damages",
      punctures: "Punctures",
      number_revitalizations: "Revitalizations",
      number_tires_pile: "Pile",
      number_tires_review: "Inspected Tires",
      number_tires: "Total Tires",
      number_vehicles_review: "Inspected Vehicles",
      number_vehicles: "Total Vehicles",
      number_review: "Inspections",
    },
    savings: {
      pressure_savings: "Pressure savings",
      revitalized_savings: "Retread savings",
      total: "Total savings",
    },
  },
  save_favorite: "Save as favorite",
  remove_favorite: "Remove from favorites",
  favorites: {
    report_tire_pile_depth: "Pile report",
    report_tire_pile_travel: "Km pile report",
    report_tire_statistics: "Tire stats",
    report_km_performance: "Best performance",
    report_km_projection: "Km Projection Report",
    report_tire_summary: "Historical Summary",
    report_tire_summary_current: "Current Summary",
    report_km_dynamic_cost: "Cost per model",
    report_renewability_index: "Renewability index",
    report_review_performance: "Review Performance",
    report_tire_po_savings: "Potential losses",
    report_tire_damage_wear: "Damages and Wears",
    report_tire_warehouse: "Warehouse Tires",
    report_user_review: "User review report",
    report_purchase: "Purchase report",
    report_last_movement: "Last Movement",
    report_vehicle_semaphore: "Semaphore Report",
    report_tire_revitalized: "Tire retread report",
    report_tire_repair: "Tire repair report",
    report_summary_mount: "Mounted tires report",
    report_summary_warehouse: "Dismounted tires report",
    report_tire_mount: "Mount tires report",
    report_tire_statistics_historic: "Tire stats (Historic)",
    report_review_performance_company: "Review performance by company",
    report_completed_cycles: "Completed cycles report",
    report_vehicle_fleet_semaphore: "Fleet mounted tires",
  },
  potential_savings_report: {
    by_brand: "By brand",
    by_model: "By model",
    by_size: "By size",
    fleet: "Fleet",
    fleet_percentage: "Fleet %",
    reviews: "Inspections",
    reviews_percentage: "Inspections %",
    reviews_percentage_over_fleet: "Inspections % / Fleet",
    reviewed_vehicles: "Inspected Vehicles",
    reviewed_vehicles_percentage: "Inspected Vehicles %",
    reviewed_vehicles_percentage_over_fleet: "Inspected Vehicles % / Fleet",
    reviewed_tires: "Inspected Tires",
    mounted: "Installed",
    mounted_percentage: "Installed %",
    mounted_percentage_over_fleet: "Installed % / Fleet",
    warehouse: "Warehouse",
    warehouse_percentage: "Warehouse %",
    warehouse_percentage_over_fleet: "Warehouse % / Fleet",
  },
  potential_savings_analysis: {
    potential_losses: "Potential losses",
    potential_losses_description:
      "All tires outside their optimal pressure range and in critical withdrawal are considered",
    pressure_losses: "Pressure losses",
    purchase_losses: "Tire purchases losses",
    month_losses: "Month losses",
    real_savings: "Real savings",
    real_savings_description:
      "Savings generated by correcting tires outside their optimal pressure and by revitalizing tires",
    pressure_savings: "Pressure savings",
    revitalized_savings: "Revitalized savings",
    month_savings: "Month savings",
    fleet_condition: "Fleet condition",
    potential_savings_losses: "Potential savings and losses",
    pressures_description:
      "Losses due to tires with abnormal pressure conditions",
    depth_new_description: "Investment in tires that must be purchased new",
    depth_revitalized_description: "Investment in tires that can be retreaded",
    real_savings_losses: "Real savings and losses",
    real_original: "Original without timely withdrawal",
    real_original_description:
      "Tires that were acquired new, which could be revitalized.",
    real_original_elements: "Acquired",
    real_revitalized: "Revitalized",
    real_revitalized_elements: "Tires revitalized",
    real_revitalized_description: "Tires that were revitalized.",
    acquisition_percentage: "Acquisition percentage",

    fleet: "Fleet",
    inspections: "Inspections",
    inspections_over_fleet: "Inspections over fleet",
    elements: "Tires",
    original_tires: "Original tires",
    critical_tires: "Critical tires",
    sent_revitalized_elements: "Tires sent",
    percentage: "Percentage",
    percentage_over_fleet: "Percentage over fleet",
    losses: "Losses",
    savings: "Savings",
    potential_savings: "Potential savings",
    original_investment: "Original investment",
    revitalized_investment: "Retread investment",
  },
  month: "Month",
  potential_savings_desc:
    "This report shows the condition of the fleet (vehicles/tires) in a selected monthly period (If a default month is not defined, it takes the month prior to the current date). This report DOES NOT SHOW THE INFORMATION for the current month. To consult this information, go to the Summary Report. The main intention of this report is to show the behavior of fleet reviews and indicate the losses and savings generated month by month.",
  summary_report_link: "Go to the summary report",
  relative_cost_text:
    "*If no renewal price has been indicated, the system will determine the approximate price of renewing the tire",
  driver_code: "Driver code",
  license: "License",
  course: "Course",
  duration: "Duration",
  months: "Months",
  days: "Days",
  day: "Day",
  min_km: "Min. Km",
  max_km: "Max. Km",
  actions: "Actions",
  activity: {
    label: "Rule out reviews",
    options: {
      not_rule_out: "Not rule out",
      general: "Rule out assembles and disassembles",
      general_and_mount: "Rule out assembles and disassembles",
    },
  },
  no_option: "No option",
  only_empty_vehicles: "Only empty vehicles",
  type_value: {
    string: "Text",
    integer: "Whole number",
    decimal: "Decimal number",
    boolean: "Check",
    date: "Date",
    numeric: "Number",
    pressure: "Pressure",
    depth: "Depth",
  },
  is_critical: "Is critical",
  sensor_check: "Requires sensor",
  ranking_value: "Value",
  use: "Use",
  use_value: {
    general: "General",
    per_tire: "Per tire",
  },
  general_data: "General data",
  headboard: "Headboard",
  body: "Body",
  disposal_chart: {
    retread: "Retread",
    original: "Original",
    total: "Total",
    others: "Others",
    "retread-1": "First retread",
    "retread-2": "Second retread",
    "retread-3": "Third retread",
    "retread-4": "Fourth retread",
    monthly: "Monthly",
    yearly: "Yearly",
    monthly_limit:
      "A maximum of 24 months can be displayed, counting backwards from the selected date or by default from the current date.",
    yearly_limit:
      "A maximum of 3 years can be displayed, counting backwards from the selected date or by default from the current date.",
    causes: {
      side_damaged_by_impact_or_break: "Side damaged by impact or break",
      crown_penetration: "Crown penetration",
      supplier_rejection: "Supplier rejection",
      cracking_on_shoulder: "Cracking on shoulder",
      belt_separation: "Belt separation",
      end_of_useful_life: "End of useful life",
      damaged_eyebrow: "Damaged eyebrow",
      unidentified: "Unidentified",
    },
    titles: {
      yearly_bill: "Annual Billing Comparison $",
      yearly_retirement: "Main Reasons for Annual Retirement",
      yearly_cost: "Annual Accumulated Cost of Discarded Rubber",
      yearly_remainder_depth: "Annual Analysis of Unused Rubber (mm)",
      yearly_by_cycle: "Annual Accumulation per Cycle (pieces)",
      yearly_by_division: "Annual Division-wise Waste Accumulation (pieces)",
      disposal_savings: "Savings on Replacement Cost",
      bill_by_cost: "Billing by $",
      bill_by_pieces: "Billing by Pieces",
      tire_equivalence: "Tire Equivalence (pieces)",
      disposal_percentage: "Monthly Waste Percentage",
      cost_by_disposal: "Cost per Discarded Rubber",
      remainder_depth: "Analysis of Unused Rubber (mm)",
      brand_analysis: "Analysis by Brand (pieces)",
      km_dispose: "Monthly Waste Travel Distance (km)",
      total_dispose: "Total Waste (pieces)",
    },
  },
  except_pile: "Except pile",
  discard: "Discard",
  discard_all: "Discard all",
  title: "Title",
  sensor: "Sensor",
  with_alerts: "With alerts",
  without_alerts: "Without alerts",
  temperature: "Temperature",
  km_day: "Km per day",
  projection_day: "Projected duration",
  withdrawal_alert: "Withdrawal alerts",
  historic_installations: "History installations tires",
  select_default: "Select a option",
  download: "Download",
  see_tutorial: "See tutorial",
  tutorials_desc:
    "Here you will find tutorials in different categories. Click on a category to see the available tutorials.",
  km_projected: "Km (Projected | Completed)",
};

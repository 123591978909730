import React from "react";
import PropTypes from "prop-types";

import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";
import ContainerTemplate from "components/templates/general/Container";
import classnames from "classnames";
import SummaryTab from "./SummaryTab";

import css from "./index.module.scss";
import "chartjs-adapter-moment";
import PileTab from "./PileTab";
import InspectionsTab from "./InspectionsTab";
import SavingsTab from "./SavingsTab";
import CostsTab from "./CostsTab";

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

function Page({
  t,
  subsidiaries,
  currentTab,
  setCurrentTab,
  graphTab,
  setGraphTab,
}) {
  return (
    <ContainerTemplate title={t("_general.home")}>
      <div className="sidebar_font container-fluid pt-2">
        <div className="container-fluid py-1 mt-3">
          <div className="sidebar_font">
            <div className={classnames(css.tab)}>
              <div
                className={classnames({
                  [css.option_tab]: true,
                  [css.active]: currentTab === "COMPANY",
                })}
                onClick={() => {
                  setCurrentTab("COMPANY");
                }}
              >
                Por Empresa
              </div>
              <div
                className={classnames({
                  [css.option_tab]: true,
                  [css.active]: currentTab === "SUBSIDIARY",
                })}
                onClick={() => {
                  setCurrentTab("SUBSIDIARY");
                }}
              >
                Por Sucursal
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="sidebar_font">
            <div className={classnames(css.tab)}>
              <div
                className={classnames({
                  [css.option_tab]: true,
                  [css.active]: graphTab === "SUMMARY",
                })}
                onClick={() => {
                  setGraphTab("SUMMARY");
                }}
              >
                Resumen
              </div>
              <div
                className={classnames({
                  [css.option_tab]: true,
                  [css.active]: graphTab === "SAVINGS",
                })}
                onClick={() => {
                  setGraphTab("SAVINGS");
                }}
              >
                Ahorros
              </div>
              <div
                className={classnames({
                  [css.option_tab]: true,
                  [css.active]: graphTab === "COSTS",
                })}
                onClick={() => {
                  setGraphTab("COSTS");
                }}
              >
                Costos / KM
              </div>
              <div
                className={classnames({
                  [css.option_tab]: true,
                  [css.active]: graphTab === "PILE",
                })}
                onClick={() => {
                  setGraphTab("PILE");
                }}
              >
                Pila
              </div>
              <div
                className={classnames({
                  [css.option_tab]: true,
                  [css.active]: graphTab === "INSPECTIONS",
                })}
                onClick={() => {
                  setGraphTab("INSPECTIONS");
                }}
              >
                Revisiones
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          {graphTab === "SUMMARY" ? (
            <SummaryTab currentTab={currentTab} />
          ) : (
            subsidiaries.length > 0 && (
              <React.Fragment>
                {graphTab === "PILE" && <PileTab currentTab={currentTab} />}
                {graphTab === "INSPECTIONS" && (
                  <InspectionsTab currentTab={currentTab} />
                )}
                {graphTab === "SAVINGS" && (
                  <SavingsTab currentTab={currentTab} />
                )}
                {graphTab === "COSTS" && <CostsTab currentTab={currentTab} />}
              </React.Fragment>
            )
          )}
        </div>
      </div>
    </ContainerTemplate>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  subsidiaries: PropTypes.array.isRequired,
  currentTab: PropTypes.string.isRequired,
  setCurrentTab: PropTypes.func.isRequired,
  graphTab: PropTypes.string.isRequired,
  setGraphTab: PropTypes.func.isRequired,
};

export default Page;
